<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItemsNew" />
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
      text: '',
      navMenuItemsNew: [
      ],
    }
  },
  created() {
    const userCheckRole = JSON.parse(localStorage.getItem('dataMenuUser'))
    if (userCheckRole) {
      userCheckRole.map(user => {
        if (user.urlResponseDTO.children.length === 0) {
          const navMenu = {
            icon: user.urlResponseDTO.icon,
            route: user.urlResponseDTO.route,
            title: user.urlResponseDTO.title,
          }
          this.navMenuItemsNew.push(navMenu)
        } else {
          user.urlResponseDTO.header = user.urlResponseDTO.title
          this.navMenuItemsNew.push(user.urlResponseDTO)
        }
      })
    }
  },
  setup() {
    return {
      // navMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
